<div class="mainsection footer wf-section">
  <div class="footer w-container">
    <div class="footercolumn column1">
      <div class="footertitle">Energy Cup  Gioca, Tifa e Vinci  2023/24</div>
     <!-- <img src="../../../assets/images/seconda-edizione/loghi-partner-energy-cup-large.png" loading="lazy" height="" alt=""> -->
      <mat-nav-list class="list-horizontal team-images">
        <a href="https://pallacanestrocantu.com/" target="_blank"><img mat-list-item src="../../../assets/images/cantu_pallacanestro.png" alt=""/></a>
        <a href="http://www.calciolecco1912.com/" target="_blank"><img mat-list-item src="../../../assets/images/calcio_lecco.png" alt=""/></a>
        <a href="https://www.verovolley.com/" target="_blank"><img mat-list-item src="../../../assets/images/vero_volley.png" alt=""/></a>
        <a href="https://www.pallacanestrovarese.it/" target="_blank"><img mat-list-item src="../../../assets/images/varese_pallacanestro.png" alt=""/></a>
        <a href="https://www.rugbysandona.it/" target="_blank"><img mat-list-item src="../../../assets/images/seconda-edizione/logo-san-dona-di-piave-rugby.png" alt=""/></a>
      </mat-nav-list>
    </div>
    <div class="div-block-2"></div>
    <div class="footercolumn column2">
      <div class="footertitle">POWERED BY</div>
      <a href="https://www.acinque.it/" target=_blank class = "text-center">
        <img src="../../../assets/images/seconda-edizione/acinque-energia-logo.png" class="acinque-img" alt="Acinque Acel" >
      </a>
    </div>
    <div class="div-block-2"></div>
    <div class="footercolumn column3">
      <div class="footertitle">SEGUICI SU</div>
      <a href="https://www.instagram.com/energy_cup_ita/" target="_blank" class="link-block-2 w-inline-block" style="margin-right: 10px;"><img src="../../../assets/images/seconda-edizione/ig.png" loading="lazy" width="120" height="" alt=""></a>
      <a href="https://www.facebook.com/Energy-Cup-107171037889892" target="_blank" class="link-block-2 w-inline-block"><img style="margin-bottom: 13px;" src="../../../assets/images/seconda-edizione/facebook-logo.png" loading="lazy" width="120" height="" alt=""></a>
      <div class="footerlinktxt">
        <a href="../../assets/pdf/Regolamento.pdf" target="_blank" class="footerlink">Regolamento</a> |
         <a href="../../privacy.html" target="_blank" class="footerlink">Privacy </a>| 
         <a href="../../assets/pdf/Modalita_partecipazione_Energy_Cup.pdf" target="_blank" class="footerlink">Termini e condizioni </a>|
         <a href="../../assets/pdf/cookie_policy.pdf" target="_blank" class="footerlink"> Cookie Policy</a>|
         <a href="../../faq.html" target="_blank" class="footerlink"> faq e Contatti</a>
      </div>
    </div>
    
  </div>
</div>
<div class="legalstrip">
  <div class="container-2 w-container">
    <div class="text-block-2">© {{year}} Promarsa Italia S.r.l. - P.IVA 13394270154 per Acinque Energia S.r.l. - Cap. Soc. € 17.100.540,00 i.v. - R.E.A. LC 326133. Reg. Imp. CCIAA di Lecco. CF e P. IVA 03773040138 </div>
  </div>
</div>